import React, { Component } from "react";

import {
  Container,
  Footer,
  Header,
  Main,
  Popover,
  Notification,
  Loading,
} from "cria-ui-react";
import { Dropdown } from "element-react";
import "element-theme-default";

import "./PainelModulos.sass";
import logo from "../../../assets/originar-sustentabilidade.png";
import VersionService from "../../../services/VersionService";
import UsuarioService from "../../../services/UsuarioService";
import { logout, isLogin } from "../../../utils";
import { Redirect } from "react-router-dom";
import Modulo from "../../elements/Modulo";
import ModuloMenu from "../../elements/ModuloMenu";
import { BASE_URL } from "../../../services/api";

import * as UsuarioActions from "../../../store/actions/usuarioActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IconeAlertas from "../../elements/Alertas/IconeAlertas";

const mapStateToProps = (state) => ({
  dadosUsuario: state.usuarioState.dadosUsuario,
  permissoes: state.usuarioState.permissoes,
  filial: state.usuarioState.filial,
  regional: state.usuarioState.regional,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, UsuarioActions), dispatch);

class PainelModulos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      version: null,
      isLogin: isLogin(),
      loading: false,
      moduloMonitoramento: null,
    };
  }

  componentDidMount() {
    this.getVersion();
    this.fetchPermissoes();
  }

  fetchPermissoes() {
    this.setState({
      loading: true,
    });

    UsuarioService.getPermissoes(
      this.props.dadosUsuario.idUsuario,
      this.props.regional,
      this.props.filial
    )
      .then((response) => {
        this.props.setPermissoes(response.data);
      })
      .catch((error) => {
        Notification.warning({
          title: "Painel Modulos",
          message: "Ocorreu um erro ao buscar as permissões do usuário",
          duration: 6000,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getVersion() {
    const { version } = this.state;
    if (!version)
      VersionService.getVersion().then(({ data }) => {
        this.setState({ version: data.version });
      });
  }

  handleLogout = () => {
    logout();
    localStorage.clear();
    this.setState({
      isLogin: false,
    });
  };

  handleCommand(command) {
    switch (command) {
      case "Conta":
        console.log(command);
        break;
      case "Configuracoes":
        console.log(command);
        break;
      case "Sair":
        this.handleLogout();
        break;
      default:
        break;
    }
  }

  render() {
    const { version } = this.state;
    const { dadosUsuario, permissoes } = this.props;

    return (
      <div id="view">
        <Loading
          loading={this.state.loading}
          text="Buscando as permissões do usuário"
        >
          <Container>
            <Header>
              <div className="cd-header--content">
                <img src={logo} className="logo" alt="Logotipo Originar" />
                <div className="left-content">
                  <div id="menu">
                    <Popover
                      placement="bottom"
                      width="288"
                      content={
                        <div className="menu-modulos">
                          {permissoes.moduloViewModels.map((item, index) => {
                            if (item.visivel) {
                              return (
                                <ModuloMenu
                                  nome={item.modulo}
                                  sigla={item.sigla}
                                  key={index}
                                  url={item.urlModulo}
                                  tokenModulo={item.tokenModulo}
                                />
                              );
                            } else return null;
                          })}
                        </div>
                      }
                    >
                      <i className="apps--icon mdi mdi-apps" />
                    </Popover>
                  </div>

                  <IconeAlertas modulos={permissoes.moduloViewModels} />

                  <div id="user">
                    <i className="user--icon mdi mdi-account-circle" />
                    <Dropdown
                      trigger="click"
                      onCommand={this.handleCommand.bind(this)}
                      menu={
                        <Dropdown.Menu>
                          <Dropdown.Item command="Conta">
                            <i className="icon-item mdi mdi-account-circle" />
                            Minha conta
                          </Dropdown.Item>
                          <Dropdown.Item command="Configuracoes">
                            <i className="icon-item mdi mdi-settings" />
                            Configurações
                          </Dropdown.Item>
                          <Dropdown.Item divided command="Sair">
                            <i className="icon-item mdi mdi-logout" />
                            Sair
                            {!this.state.isLogin && (
                              <Redirect to={`${BASE_URL}login`} />
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      }
                    >
                      <span className="el-dropdown-link">
                        <span className="user--name">{dadosUsuario.nome}</span>
                        <i className="el-icon-caret-bottom el-icon--right"></i>
                      </span>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Header>

            <Container direction="vertical">
              <div className="subheader">
                <div className="subheader--content">
                  <p className="title">Bem-vindo à Plataforma Originar.</p>
                  <p className="subTitle">
                    Esses são os módulos aos quais você possui acesso.
                  </p>
                </div>
              </div>
              <Main>
                <div className="cd-main--content">
                  {permissoes.moduloViewModels.map((item, index) => {
                    if (item.visivel) {
                      return (
                        <Modulo
                          nome={item.modulo}
                          img={item.imagemModulo}
                          url={item.urlModulo}
                          key={index}
                          tokenModulo={item.tokenModulo}
                          sigla={item.sigla}
                          desabilitado={item.desabilitado}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <Footer>
                  © {new Date().getFullYear()} - Portal Segurança
                  {version && <div className="version-number">{version}</div>}
                </Footer>
              </Main>
            </Container>
          </Container>
        </Loading>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PainelModulos);
