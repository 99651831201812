import {
    SET_DADOS_USUARIO,
    SET_PERMISSOES,
    RESET_STATE,
    SET_FILIAL,
    SET_REGIONAL
} from '../actionTypes';

import { PERMISSOES } from '../../models/Permissoes';

const dadosStorage = JSON.parse(localStorage.getItem("usuario"));
const filialStorage = JSON.parse(localStorage.getItem("filial"));
const regionalStorage = JSON.parse(localStorage.getItem("regional"));

const INITIAL_STATE = {    
    dadosUsuario: dadosStorage || {},
    permissoes: PERMISSOES,
    filial: filialStorage || {},
    regional: regionalStorage || {}
}

export const usuarioReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_STATE:
            return {
                ...INITIAL_STATE
            };
        case SET_DADOS_USUARIO:
            localStorage.setItem("usuario", JSON.stringify(action.dadosUsuario))
            return {
                ...state,
                dadosUsuario: action.dadosUsuario
            };
        case SET_PERMISSOES: 
            return {
                ...state,
                permissoes: action.permissoes
            };
        case SET_FILIAL:
            localStorage.setItem("filial", JSON.stringify(action.filial))
            return {
                ...state,
                filial: action.filial
            };
        case SET_REGIONAL:
            localStorage.setItem("regional", JSON.stringify(action.regional))
            return {
                ...state,
                regional: action.regional
            }   
        default: 
            return state;        
    }
};