import { usuarioReducer } from './usuarioReducer';
import { combineReducers } from 'redux';

/*  Reducer que possibilita o acesso à última ação disparada no subscribe() da store.
    Extraído de https://github.com/reduxjs/redux/issues/580#issuecomment-133188511 */
function lastAction(state = null, action) {
    return action;
}

export const rootReducer = combineReducers(
    {
        usuarioState: usuarioReducer,
        lastAction
    }
);
