import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ModuloMenu.sass';

export default class ModuloMenu extends Component {

    render() {
        const { nome, sigla } = this.props;

        return (
                <a href={this.props.url + '?accessToken=' + this.props.tokenModulo} style={{ textDecoration: 'none' }}>
                    <div className="menu-container">
                        <div className="mini-container">
                            <span className="sigla">{sigla}</span>
                        </div>
                        <span className="label">{nome}</span>
                    </div>
                </a>
        );
    }
}

ModuloMenu.propTypes = {
    nome: PropTypes.string,
    sigla: PropTypes.string,
    url: PropTypes.string,
    tokenModulo: PropTypes.string
}