import React from "react";

import { Button, Modal } from "cria-ui-react";
import { Layout } from "element-react";

const AlertaExpandido = ({ alerta, fecharModal }) => {
  const redirecionarVisaoFazenda = () => {
    if (alerta.idFazenda) {
      fecharModal();
    }
  };

  return (
    <Modal
      visible={alerta}
      size="small"
      onCancel={fecharModal}
      lockScroll={false}
      customClass="modal-alerta-expandido_wrapper"
      showClose={true}
    >
      <Modal.Body className="modal-alerta-expandido_content">
        <br />
        <Layout.Row type="flex">
          <Layout.Col sm="1" md="1" lg="1">
            <div className="icon-alerta">
              <i className="mdi mdi-email-open-outline" />
            </div>
          </Layout.Col>
          <Layout.Col sm="23" md="23" lg="23">
            <div className="text-alerta-expandido">
              <h4>{alerta?.alerta || alerta?.assunto}</h4>
            </div>
          </Layout.Col>
        </Layout.Row>
        <hr />
        <br />
        <p className="modal-alerta-expandido_informacao">
          {alerta?.informacao}
        </p>
        <Button type="text" onClick={redirecionarVisaoFazenda}>
          Acessar detalhes da fazenda
        </Button>
      </Modal.Body>
      <Modal.Footer className="modal-alerta-expandido_footer"></Modal.Footer>
    </Modal>
  );
};

export default AlertaExpandido;
