import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Login from '../src/components/views/Login';
import PainelModulos from '../src/components/views/PainelModulos';
import SelecionarFilial from '../src/components/views/SelecionarFilial';
import PrivateRoute from '../src/components/elements/PrivateRoute';
import PublicRoute from '../src/components/elements/PublicRoute';
import { BASE_URL } from './services/api';

const Router = () => (
    <BrowserRouter>
        <Switch>
            <PublicRoute restricted={false} path={`${BASE_URL}login`} component={Login}/>
            <PrivateRoute restricted={true} path={`${BASE_URL}portal-seguranca/selecionar-filial`} component={SelecionarFilial}/>
            <PrivateRoute restricted={true} path={`${BASE_URL}portal-seguranca/painel-modulos`} component={PainelModulos}/>

            {/* Rota para facilitar desenvolvimento. */}
            <PublicRoute restricted={false} path={BASE_URL} component={Login}/>
        </Switch>
    </BrowserRouter>
);

export default Router;
