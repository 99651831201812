import {
    SET_DADOS_USUARIO,
    SET_PERMISSOES,
    SET_FILIAL,
    SET_REGIONAL,
    RESET_STATE
} from '../actionTypes';

export const resetState = () => {
    return { type: RESET_STATE };
};

export const setDadosUsuario = (dadosUsuario) => {
    return {
        type: SET_DADOS_USUARIO,
        dadosUsuario
    }
};

export const setPermissoes = (permissoes) => {
    return {
        type: SET_PERMISSOES,
        permissoes
    }
};

export const setFilial = (filial) => {
    return {
        type: SET_FILIAL,
        filial
    }
};

export const setRegional = (regional) => {
    return {
        type: SET_REGIONAL,
        regional
    }
};