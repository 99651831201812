import React, { useEffect, useState } from "react";

import { Table, Checkbox, Pagination, Button } from "element-react";
import { Loading, Modal } from "cria-ui-react";

import "./Alertas.scss";
import AlertaExpandido from "./AlertaExpandido";
import AlertaService from "../../../services/AlertaService";

export default function TodosAlertas(props) {
  const [dadosAlertas, setDadosAlertas] = useState([]);
  const [alertasSelecionados, setAlertasSelecionados] = useState([]);
  const [alertaExpandidoCompleto, setAlertaExpandidoCompleto] = useState(null);
  const [paginaAtual, setPaginaAtual] = useState(null);
  const [carregando, setCarregando] = useState(false);

  const TAMANHO_PAGINA = 10;

  const colunas = [
    {
      width: 50,
      render: function (alerta) {
        return (
          <div
            style={{
              display: "flex",
              alignContent: "cente",
              justifyContent: "center",
            }}
          >
            {!destacarAlerta(alerta) ? (
              <i
                className={
                  alerta.lido ? "mdi mdi-email-open-outline" : "mdi mdi-email"
                }
                style={{ fontSize: "18px" }}
                title={alerta.lido ? "Alerta lido" : "Marcar como lido"}
              />
            ) : (
              <i
                className={
                  alerta.lido
                    ? "mdi mdi-email-open-outline destaque"
                    : "mdi mdi-email destaque"
                }
                style={{ fontSize: "18px" }}
                title={alerta.lido ? "Alerta lido" : "Marcar como lido"}
              />
            )}
          </div>
        );
      },
    },
    {
      label: "Assunto",
      minWidth: 300,
      render: function (alerta) {
        return (
          <p
            style={{ fontWeight: !alerta.lido ? "500" : "400" }}
            title={alerta.tipo.nome}
            className={
              destacarAlerta(alerta) ? "alerta-title destaque" : "alerta-title"
            }
            onClick={() => {
              expandirAlerta(alerta);
            }}
          >
            {alerta.assunto}
          </p>
        );
      },
    },
    {
      label: "Data do alerta",
      width: 150,
      align: "center",
      render: function (alerta) {
        return (
          <div
            style={{ fontWeight: !alerta.lido ? "500" : "400" }}
            className="alerta-data_ver_todos"
          >
            {alerta?.dataFormatada}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (paginaAtual) buscarAlertas();
  }, [paginaAtual]);

  const buscarAlertas = () => {
    setCarregando(true);
    AlertaService.buscarAlertas(paginaAtual || 1, props.aba, null, 10).then(
      ({ data }) => {
        setDadosAlertas(data);
        setAlertasSelecionados([]);
        setCarregando(false);
      }
    );
  };

  const selecionarAlerta = (alertas) => {
    setAlertasSelecionados(alertas);
  };

  const expandirAlerta = (alerta) => {
    Promise.all([
      AlertaService.buscarAlertaCompleto(alerta.id),
      AlertaService.marcarAlertaLido([alerta.id]),
    ]).then(([resposta, _]) => {
      buscarAlertas();
      let assunto = alerta.assunto;
      setAlertaExpandidoCompleto({ ...resposta.data, assunto });
    });
  };

  const marcarSelecionadosComoLido = () => {
    setCarregando(true);
    AlertaService.marcarAlertaLido(alertasSelecionados.map((a) => a.id))
      .then(() => buscarAlertas())
      .finally(() => setCarregando(false));
  };

  const removerSelecionados = () => {
    setCarregando(true);
    AlertaService.removerAlertas(alertasSelecionados.map((a) => a.id))
      .then(() => buscarAlertas())
      .finally(() => setCarregando(false));
  };

  const getIntervaloExibicaoPaginacao = (resultados) => {
    const inicial = (paginaAtual - 1) * TAMANHO_PAGINA + 1;
    let calc = (paginaAtual - 1) * TAMANHO_PAGINA;
    const final = Math.min(
      paginaAtual * TAMANHO_PAGINA,
      resultados.totalAlertas
    );
    return `Exibindo ${inicial} - ${final} de ${resultados.totalAlertas} registro(s)`;
  };

  function destacarAlerta(mensagem) {
    if (mensagem) {
      return (
        mensagem.tipo.codigo === "INICIO_PLANTIO_RESTRICAO" ||
        mensagem.tipo.codigo === "INICIO_COLHEITA_RESTRICAO"
      );
    }
  }

  return (
    <Modal
      visible={props.alerta}
      size={window.screen.availWidth < 1366 ? "large" : "medium"}
      onCancel={props.fecharModal}
      lockScroll={false}
      customClass="modal-alerta-expandido_wrapper"
      showClose={true}
      onOpen={() => buscarAlertas()}
    >
      <Modal.Body className="modal-alerta-expandido_content">
        <div className="todos-alertas_wrapper">
          <div className="todos-alertas_header">
            <i>Clique no assunto do alerta para visualizar a mensagem.</i>
          </div>
          <Loading loading={carregando} text="Carregando os dados...">
            <div className="todos-alertas_main">
              <div className="todos-alertas_lista-wrapper">
                <div className="todos-alertas_lista">
                  {dadosAlertas && dadosAlertas.alertas ? (
                    <Table
                      style={{ width: "100%" }}
                      columns={colunas}
                      data={dadosAlertas.alertas}
                      border={false}
                      onSelectChange={(alerta) => selecionarAlerta(alerta)}
                      displayRowCheckbox={false}
                      rowStyle={{ cursor: "pointer" }}
                    />
                  ) : (
                    <div className="componente-alertas_vazio">
                      <div>Não há alertas no momento.</div>
                      <i className="mdi mdi-bell-off-outline" />
                    </div>
                  )}
                </div>

                <div className="componente-filtros__paginacao--wrapper">
                  <Pagination
                    layout="prev, pager, next"
                    total={dadosAlertas.totalAlertas}
                    pageSize={TAMANHO_PAGINA}
                    currentPage={paginaAtual}
                    onCurrentChange={(paginaAtual) => {
                      setPaginaAtual(paginaAtual);
                    }}
                  />
                  <span className="componente-filtros__paginacao--count">
                    {getIntervaloExibicaoPaginacao(dadosAlertas)}
                  </span>
                </div>
              </div>
            </div>
          </Loading>
          <div className="todos-alertas_footer">
            {!!alertasSelecionados.length && (
              <div
                className="todos-alertas_footer-btn info"
                onClick={marcarSelecionadosComoLido}
              >
                <i className="mdi mdi-email-open-outline" />
                Marcar como lida
              </div>
            )}
            {!!alertasSelecionados.length && (
              <div
                className="todos-alertas_footer-btn danger"
                onClick={removerSelecionados}
              >
                <i className="mdi mdi-trash-can" />
                Excluir selecionada(s)
              </div>
            )}
          </div>
        </div>
        <AlertaExpandido
          alerta={alertaExpandidoCompleto}
          fecharModal={() => setAlertaExpandidoCompleto(null)}
          aba={props.aba}
        />
      </Modal.Body>
      <Modal.Footer className="modal-alerta-expandido_footer"></Modal.Footer>
    </Modal>
  );
}
