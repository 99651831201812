import React, { Component } from 'react';

import './Modulo.sass'
import PropTypes from 'prop-types';

export default class Modulo extends Component {

    // Retorna a URL de acesso ao módulo
    getUrl() {
        return !this.props.desabilitado ? this.props.url + '?accessToken=' + this.props.tokenModulo : null;
    }

    render() {
        return (
            <a href={this.getUrl()} style={{textDecoration: 'none'}}>
                <div className={`${this.props.desabilitado ? 'modulo not-allowed' : 'modulo'}`}
                    style={
                        {
                            backgroundImage: `url(data:image/png;base64,${this.props.img})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                    <div className="footer">
                        <span className="modulo-name">{this.props.nome}</span>
                        {(this.props.desabilitado &&
                            <i className="lock-icon mdi mdi-lock" />
                        )}
                    </div>
                </div>
            </a>
        );
    }
}

Modulo.propTypes = {
    nome: PropTypes.string,
    img: PropTypes.any,
    urlModulo: PropTypes.string,
    tokenModulo: PropTypes.string,
    desabilitado: PropTypes.bool
}