const TOKEN_KEY = 'jwt_portal-seguranca';

export const login = () => {
    var dadosUsuario = JSON.parse(localStorage.getItem("usuario"));
    localStorage.setItem(TOKEN_KEY, dadosUsuario.accessToken);
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }    
    return false;
}

export const getAccessToken = () => {
    if (localStorage.getItem(TOKEN_KEY)){
        return localStorage.getItem(TOKEN_KEY);
    }else {
        return {};
    }   
}

export const getAuthorizationHeader = () => {
    if (localStorage.getItem(TOKEN_KEY)){
        return  {'Authorization': getAccessToken()};
    }else{
        return {};
    }
}