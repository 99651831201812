import React, { Component } from "react";
import "./SelecionarFilial.scss";
import logo from "../../../assets/originar-sustentabilidade.png";
import VersionService from "../../../services/VersionService";
import UsuarioService from "../../../services/UsuarioService";
import {
  Container,
  Footer,
  Header,
  Main,
  Form,
  Layout,
  Button,
  Select,
  Notification,
  Loading,
} from "cria-ui-react";
import { Dropdown } from "element-react";
import "element-theme-default";
import { logout, isLogin } from "../../../utils";
import { Redirect } from "react-router-dom";
import * as UsuarioActions from "../../../store/actions/usuarioActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ArraysHelpers from "../../../utils/helpers/Array";
import { BASE_URL } from "../../../services/api";
import IconeAlertas from "../../elements/Alertas/IconeAlertas";

const mapStateToProps = (state) => ({
  dadosUsuario: state.usuarioState.dadosUsuario,
  permissoes: state.usuarioState.permissoes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, UsuarioActions), dispatch);

class SelecionarFilial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      version: null,
      selectedData: [],
      isLogin: isLogin(),
      loading: false,
      form: {
        regional: "",
        filial: "",
      },
    };
  }

  componentDidMount() {
    this.getVersion();
    this.fetchFiliais();
  }

  fetchFiliais() {
    this.setState({
      loading: true,
    });

    UsuarioService.getFiliais(this.props.dadosUsuario.idUsuario)
      .then((response) => {
        this.setState({
          selectedData: response.data,
        });
      })
      .catch((error) => {
        Notification.warning({
          title: "Selecionar Filial",
          message:
            "Ocorreu um erro ao buscar as filiais filiadas à esse usuário",
          duration: 6000,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getVersion() {
    const { version } = this.state;
    if (!version)
      VersionService.getVersion().then(({ data }) => {
        this.setState({ version: data.version });
      });
  }

  handleLogout = () => {
    logout();
    localStorage.clear();
    this.setState({
      isLogin: false,
    });
  };

  handleCommand(command) {
    switch (command) {
      case "Conta":
        console.log(command);
        break;
      case "Configuracoes":
        console.log(command);
        break;
      case "Sair":
        this.handleLogout();
        break;
      default:
        break;
    }
  }

  handleSubmit(e) {
    this.props.history.push(`${BASE_URL}portal-seguranca/painel-modulos`);
  }

  onSelectRegional(key, value) {
    this.setState({
      form: {
        regional: value,
        filial: "",
      },
    });

    this.props.setRegional(value);
    this.props.setFilial(-1);
  }

  onSelectFilial(key, value) {
    this.setState({
      form: Object.assign({}, this.state.form, { [key]: value }),
    });

    this.props.setFilial(value);
  }

  render() {
    const { version, selectedData } = this.state;
    const { dadosUsuario } = this.props;

    return (
      <div className="container-filial">
        <Loading
          loading={this.state.loading}
          text="Buscando as regionais e as filiais"
        >
          <Header>
            <div className="cd-header--content">
              <img src={logo} className="logo" alt="Logotipo Originar" />
              <div className="left-content">
                <div id="user">
                  <i className="user--icon mdi mdi-account-circle" />
                  <Dropdown
                    trigger="click"
                    onCommand={this.handleCommand.bind(this)}
                    menu={
                      <Dropdown.Menu>
                        <Dropdown.Item command="Conta">
                          <i className="icon-item mdi mdi-account-circle" />
                          Minha conta
                        </Dropdown.Item>
                        <Dropdown.Item command="Configuracoes">
                          <i className="icon-item mdi mdi-settings" />
                          Configurações
                        </Dropdown.Item>
                        <Dropdown.Item divided command="Sair">
                          <i className="icon-item mdi mdi-logout" />
                          Sair
                          {!this.state.isLogin && (
                            <Redirect to={`${BASE_URL}login`} />
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    }
                  >
                    <span className="el-dropdown-link">
                      <span className="user--name">{dadosUsuario.nome}</span>
                      <i className="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Header>

          <Container direction="vertical">
            <Main>
              <div className="cd-main--content">
                <Layout.Row className="form-filial">
                  <Layout.Row xs={24} sm={24} md={12}>
                    <p className="title-form">
                      Olá
                      {dadosUsuario != null
                        ? " " + dadosUsuario.nome.split(" ")[0]
                        : null}
                    </p>
                    <p className="subtitle-form">
                      Selecione uma regional e filial para prosseguir.
                    </p>
                  </Layout.Row>
                  <Form
                    ref="form"
                    labelPosition="top"
                    className="form-content"
                    model={this.state.form}
                  >
                    <Form.Item label="Regional" prop="regional">
                      <Layout.Row xs={24} sm={24} md={12}>
                        <Select
                          id="selecionar-regional"
                          value={this.state.form.regional}
                          placeholder="Selecione uma regional"
                          onSelect={this.onSelectRegional.bind(
                            this,
                            "regional"
                          )}
                        >
                          {ArraysHelpers.checkArray(selectedData) &&
                            selectedData.map((item) => {
                              return (
                                <Select.Option
                                  label={item.regional.nome}
                                  value={item.regional.id}
                                  key={item.regional.id}
                                />
                              );
                            })}
                        </Select>
                      </Layout.Row>
                    </Form.Item>
                    <Form.Item label="Filial" prop="filial">
                      <Layout.Row xs={24} sm={24} md={12}>
                        <Select
                          id="selecionar-filial"
                          value={this.state.form.filial}
                          placeholder={
                            this.state.form.regional === -1
                              ? "TODAS"
                              : "Selecione um filial"
                          }
                          onSelect={this.onSelectFilial.bind(this, "filial")}
                          disabled={
                            this.state.form.regional === "" ||
                            this.state.form.regional === -1
                          }
                        >
                          {ArraysHelpers.checkArray(selectedData) &&
                            this.state.form.regional !== "" &&
                            selectedData
                              .filter(
                                (item) =>
                                  item.regional.id === this.state.form.regional
                              )[0]
                              .filiais.map((item) => {
                                return (
                                  <Select.Option
                                    label={item.nome}
                                    value={item.id}
                                    key={item.id}
                                  />
                                );
                              })}
                        </Select>
                      </Layout.Row>
                    </Form.Item>
                    <Layout.Row xs={24} sm={24} md={12}>
                      <Form.Item>
                        <Button
                          type="primary"
                          icon="mdi mdi-check"
                          onClick={this.handleSubmit.bind(this)}
                          disabled={
                            this.state.form.filial === "" &&
                            this.state.form.regional !== -1
                          }
                        >
                          Selecionar
                        </Button>
                      </Form.Item>
                    </Layout.Row>
                  </Form>
                </Layout.Row>
              </div>
              <Footer className={"version"}>
                © {new Date().getFullYear()} - Portal Segurança
                {version && <div className="version-number">{version}</div>}
              </Footer>
            </Main>
          </Container>
        </Loading>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelecionarFilial);
