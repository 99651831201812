import axios from "axios";

export default {
  buscarAlertas(
    pagina,
    codigoGrupo,
    idFazenda,
    tamanhoPagina,
    idAlerta,
    token
  ) {
    return axios.get("/monitoramento/api/alerta/listaralertas", {
      params: { pagina, tamanhoPagina, codigoGrupo, idFazenda, idAlerta },
      responseType: "json",
      headers: {
        Authorization: token,
      },
    });
  },
  marcarAlertaLido(idsAlertas, token) {
    return axios.post("/monitoramento/api/alerta/marcarlido", idsAlertas, {
      headers: {
        Authorization: token,
      },
    });
  },
  removerAlertas(idsAlertas, token) {
    return axios.post("/monitoramento/api/alerta/remover", idsAlertas, {
      headers: {
        Authorization: token,
      },
    });
  },
  buscarAlertaExpandido(idAlerta, token) {
    return axios.get("/monitoramento/api/alerta/descricao/" + idAlerta, {
      headers: {
        Authorization: token,
      },
    });
  },
  buscarAlertaCompleto(idAlerta, token) {
    return axios.get("/monitoramento/api/alerta/completo/" + idAlerta, {
      headers: {
        Authorization: token,
      },
    });
  },
};
