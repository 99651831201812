import api from './api'
import { getAuthorizationHeader } from '../utils'

export default {

    async getPermissoes(idUsuario, idRegional, idFilial) {
        const requestOptions = {
            headers: getAuthorizationHeader()
        };
        return await api.get(`/api/Usuario/${idUsuario}/permissoes/${idRegional}/${idFilial}/true`, requestOptions);
    },

    async login(command){
        return await api.post(`api/Usuario/Login`, command);
    },

    async getFiliais(idUsuario) {
        const requestOptions = {
            headers: getAuthorizationHeader()
        };
        return await api.get(`/api/Usuario/${idUsuario}/filiais`, requestOptions);
    } 
};