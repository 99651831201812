export const PERMISSOES = {
    perfil: null, 
    moduloViewModels: [ {
        modulo: null,
        urlModulo: null,
        imagemModulo: null,
        consultar: null,
        inserir: null, 
        atualizar: null,
        deletar: null,
        sigla: null,
        visivel: null,
        ativo: null,
    }]
};