import React, { Component } from 'react';
import { Layout, Form, Input, Button, Notification, Loading } from 'cria-ui-react'

import './Login.scss';
import logoImg from '../../../assets/originar-sustentabilidade.png';
import VersionService from '../../../services/VersionService';
import { login } from '../../../utils';
import UsuarioService from '../../../services/UsuarioService';

import * as UsuarioActions from '../../../store/actions/usuarioActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BASE_URL } from '../../../services/api';

const mapStateToProps = state => ({
    dadosUsuario: state.usuarioState.dadosUsuario
});

const mapDispatchToProps = dispatch => bindActionCreators(
    Object.assign({}, UsuarioActions), dispatch
);

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            version: null,
            loading: false,
            dadosUsuario: this.props.dadosUsuario,
            form: {
                login: '',
                senha: ''
            },
            rules: {
                senha: [
                    { message: 'Por favor, digite a senha', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (value === '') {
                                callback(new Error('Por favor, digite a senha'));
                            } else {
                                callback();
                            }
                        }
                    }
                ],
                login: [
                    { message: 'Por favor, digite o nome de usuário', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (value === '') {
                                callback(new Error('Por favor, digite o nome de usuário'));
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }
        }
        localStorage.clear();
        this.getVersion();
    }

    handleLogin() {
        this.setState({
            loading : true
        })        

        return UsuarioService.login(this.state.form)
            .then((response) => {
                this.props.setDadosUsuario(response.data);
                login();
                this.props.history.push(`${BASE_URL}portal-seguranca/selecionar-filial`);                
            })
            .catch(({ response }) => {
                Notification.error({
                    title: response.data.failures && response.data.failures.length > 0
                        ? response.data.message
                        : 'Não foi possível fazer o login',
                    message: response.data.failures && response.data.failures.length > 0
                        ? response.data.failures.join(', ')
                        : response.data.message,
                    duration: 8000
                });
                return Promise.reject();
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            });

    }

    getVersion() {
        const { version } = this.state;
        if (!version) VersionService.getVersion()
            .then(({ data }) => {
                this.setState({ version: data.version })
            });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.refs.form.validate((valid) => {
            if (valid) {
                this.handleLogin();
            } else {
                console.log("error submit!!");
                return false;
            }
        });
    }

    onChange(key, value) {
        this.setState({
            form: Object.assign({}, this.state.form, { [key]: value })
        });
    }

    handleEnterPressed(e) {
        if (e.key === 'Enter') {
            this.handleSubmit(e);
        }
    }

    render() {
        const { version } = this.state;
        return (
            <Loading loading={this.state.loading} text="Autenticando o usuário e a senha">
                <div className="container">
                    <Layout.Row >
                        <img src={logoImg} className="logo" alt="logotipo da plataforma originar"></img>
                    </Layout.Row>
                    <Layout.Row className="form-login">
                        <Form ref="form" labelPosition='top' model={this.state.form} rules={this.state.rules}>
                            <Layout.Row xs={24} sm={24} md={12}>
                                <Form.Item label="Usuário" prop="login">
                                    <Input
                                        value={this.state.form.login}
                                        placeholder="Digite o nome de usuário"
                                        suffixIcon="mdi mdi-account"
                                        onChange={this.onChange.bind(this, 'login')}></Input>
                                </Form.Item>
                            </Layout.Row>
                            <Layout.Row xs={24} sm={24} md={12}>
                                <Form.Item label="Senha" prop="senha">
                                    <Input
                                        type="password" value={this.state.form.senha}
                                        placeholder="Digite sua senha"
                                        suffixIcon="mdi mdi-key"
                                        onChange={this.onChange.bind(this, 'senha')}
                                        onKeyDown={this.handleEnterPressed.bind(this)}>
                                    </Input>
                                </Form.Item>
                            </Layout.Row>
                            <Layout.Row xs={24} sm={24} md={12}>
                                <Form.Item>
                                    <Button type="primary" icon="mdi mdi-login" onClick={this.handleSubmit.bind(this)}>Entrar</Button>
                                </Form.Item>
                            </Layout.Row>
                        </Form>
                    </Layout.Row>
                    <Layout.Row className="footer">
                        <div className="version">© {new Date().getFullYear()} - Plataforma Originar {version && <div className="version-number">{version}</div>}</div>
                    </Layout.Row>
                </div>
            </Loading>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Login);