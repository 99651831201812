import React from 'react';
import './styles/main.scss';
import Router from '../src/router'

function App() {
  return (
    <Router/>
  );
}

export default App;
