import React, { useEffect, useState } from "react";

import { Popover, Loading } from "cria-ui-react";

import AlertaService from "../../../services/AlertaService";

import "./Alertas.scss";
import Alertas from ".";

export default function IconeAlertas({ modulos }) {
  const [dadosAlertas, setDadosAlertas] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [moduloMonitoramento, setModuloMonitoramento] = useState({});

  useEffect(() => buscarAlertas(), [modulos]);

  const buscarAlertas = () => {
    setCarregando(true);

    const moduloMonitoramento = modulos.find(
      (item) => item.modulo == "Monitoramento"
    );

    setModuloMonitoramento(moduloMonitoramento);

    if (moduloMonitoramento)
      AlertaService.buscarAlertas(
        1,
        "SOCIOAMBIENTAL",
        null,
        10,
        null,
        moduloMonitoramento?.tokenModulo
      ).then(({ data }) => {
        setDadosAlertas(data);
        setCarregando(false);
      });
  };

  function getAlertasNaoLidos() {
    if (dadosAlertas?.naoLido) return dadosAlertas.naoLido.total;
    else return 0;
  }

  return (
    <div className="icone-alertas">
      <Popover
        placement="bottom-start"
        title={
          <div className="popup-header">
            <div className="popup-header_section">
              <i className="mdi mdi-bell" style={{ cursor: "pointer" }} />
              <span className="popup-title">
                Notificações ({getAlertasNaoLidos()})
              </span>
            </div>
            <div className="popup-header_section">
              <a
                className="show-alertas"
                href={
                  moduloMonitoramento?.urlModulo +
                  "#/notificacoes/" +
                  "?accessToken=" +
                  moduloMonitoramento?.tokenModulo
                }
              >
                Abrir painel de notificações &gt;
              </a>
            </div>
          </div>
        }
        width={1000}
        visible={true}
        content={
          <Loading loading={carregando} text="Carregando os dados...">
            <Alertas
              listaAlertas={dadosAlertas}
              moduloMonitoramento={moduloMonitoramento}
            />
          </Loading>
        }
        popperClass="popup-alertas"
      >
        <button
          className="btn-alertas_wrapper"
          onClick={buscarAlertas}
          type="button"
        >
          <i className="mdi mdi-bell" />
          <span className="btn-alertas_titulo">{getAlertasNaoLidos()}</span>
        </button>
      </Popover>
    </div>
  );
}
