import React, { useEffect, useState } from "react";
import { Tabs } from "element-react";

import { format, formatDistanceToNowStrict } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

import { Loading, Collapse } from "cria-ui-react";

import TodosAlertas from "./TodosAlertas";

import "./Alertas.scss";
import AlertaExpandido from "./AlertaExpandido";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AlertaService from "../../../services/AlertaService";

const Alertas = ({ listaAlertas, moduloMonitoramento }) => {
  const [alertaExpandido, setAlertaExpandido] = useState(null);
  const [todosAlertas, setTodosAlertas] = useState(null);
  const [carregando, setCarregando] = useState(false);
  const [mensagensAlerta, setMensagensAlerta] = useState([]);
  const [abaAtiva, setAbaAtiva] = useState("SOCIOAMBIENTAL");

  useEffect(() => {
    setMensagensAlerta(listaAlertas.alertas);
  }, [listaAlertas]);

  async function tabClick(tab) {
    setCarregando(true);

    await AlertaService.buscarAlertas(
      1,
      tab.props.name,
      null,
      10,
      null,
      moduloMonitoramento?.tokenModulo
    ).then(({ data }) => {
      setMensagensAlerta(data.alertas);
      setCarregando(false);
      setAbaAtiva(tab.props.name);
    });
  }

  function destacarAlerta(mensagem) {
    if (mensagem) {
      return (
        mensagem.tipo.codigo === "INICIO_PLANTIO_RESTRICAO" ||
        mensagem.tipo.codigo === "INICIO_COLHEITA_RESTRICAO"
      );
    }
  }

  return (
    <div className="componente-alertas">
      {
        <div className="componente-alertas_vazio">
          <Tabs
            activeName="SOCIOAMBIENTAL"
            className="tabs-alertas"
            onTabClick={tabClick}
          >
            <Tabs.Pane name="SOCIOAMBIENTAL" label="Sociambiental">
              <Loading
                loading={carregando}
                text="Carregando os alertas socioambientais..."
              >
                {
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Últimos 10 alertas
                      <span className="alerts-by-day-count">
                        {mensagensAlerta?.length}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      {mensagensAlerta?.length > 0 ? (
                        mensagensAlerta.map((msg) => (
                          <Collapse>
                            <Collapse.Item
                              title={
                                <>
                                  <span
                                    className={
                                      destacarAlerta(msg)
                                        ? "alerta-nao-lido destaque"
                                        : "alerta-nao-lido"
                                    }
                                  >
                                    {!destacarAlerta(msg) ? (
                                      <i
                                        className="mdi mdi-alert icon-alerta"
                                        style={{ color: "#E6A23C" }}
                                      />
                                    ) : (
                                      <i
                                        className="mdi mdi-alert icon-alerta destaque"
                                        style={{ color: "#E6A23C" }}
                                      />
                                    )}
                                    {msg.assunto}
                                  </span>
                                  <span className="dateToNow">
                                    {formatDistanceToNowStrict(
                                      new Date(msg.dataCadastro),
                                      {
                                        addSuffix: true,
                                        locale: ptBr,
                                      }
                                    )}
                                  </span>
                                </>
                              }
                            >
                              <div className="detailsGrid">
                                <strong className="fazendaText">
                                  Nome da fazenda:
                                  <span>{msg.fazenda.nome}</span>
                                </strong>
                                <strong>
                                  Filial: <span>{msg.filial.nome}</span>
                                </strong>
                                <strong>
                                  Regional:
                                  <span>{msg.regional.nome}</span>
                                </strong>
                                <strong>
                                  Data do alerta:
                                  <span>
                                    {format(
                                      new Date(msg.dataCadastro),
                                      "dd/MM/yyyy"
                                    )}
                                  </span>
                                </strong>
                              </div>
                              <a
                                href={
                                  moduloMonitoramento?.urlModulo +
                                  "#/fazenda/" +
                                  msg.fazenda.id +
                                  "?accessToken=" +
                                  moduloMonitoramento?.tokenModulo
                                }
                                className="alerta-details"
                              >
                                Acessar detalhes da fazenda
                              </a>
                            </Collapse.Item>
                          </Collapse>
                        ))
                      ) : (
                        <div>Não há alertas no momento.</div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                }
              </Loading>
            </Tabs.Pane>
            <Tabs.Pane name="AGRICOLA" label="Agrícola">
              <Loading
                loading={carregando}
                text="Carregando os alertas agrícolas..."
              >
                {
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Últimos 10 alertas
                      <span className="alerts-by-day-count">
                        {mensagensAlerta?.length}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      {mensagensAlerta?.length > 0 ? (
                        mensagensAlerta.map((msg) => (
                          <Collapse>
                            <Collapse.Item
                              title={
                                <>
                                  <span
                                    className={
                                      destacarAlerta(msg)
                                        ? "alerta-nao-lido destaque"
                                        : "alerta-nao-lido"
                                    }
                                  >
                                    {!destacarAlerta(msg) ? (
                                      <i
                                        className="mdi mdi-alert icon-alerta"
                                        style={{ color: "#E6A23C" }}
                                      />
                                    ) : (
                                      <i
                                        className="mdi mdi-alert icon-alerta destaque"
                                        style={{ color: "#E6A23C" }}
                                      />
                                    )}
                                    {msg.assunto}
                                  </span>
                                  <span className="dateToNow">
                                    {formatDistanceToNowStrict(
                                      new Date(msg.dataCadastro),
                                      {
                                        addSuffix: true,
                                        locale: ptBr,
                                      }
                                    )}
                                  </span>
                                </>
                              }
                            >
                              <div className="detailsGrid">
                                <strong className="fazendaText">
                                  Nome da fazenda:
                                  <span>{msg.fazenda.nome}</span>
                                </strong>
                                <strong>
                                  Filial: <span>{msg.filial.nome}</span>
                                </strong>
                                <strong>
                                  Regional:
                                  <span>{msg.regional.nome}</span>
                                </strong>
                                <strong>
                                  Data do alerta:
                                  <span>
                                    {format(
                                      new Date(msg.dataCadastro),
                                      "dd/MM/yyyy"
                                    )}
                                  </span>
                                </strong>
                              </div>
                              <a
                                href={
                                  moduloMonitoramento?.urlModulo +
                                  "#/fazenda/" +
                                  msg.fazenda.id +
                                  "?accessToken=" +
                                  moduloMonitoramento?.tokenModulo
                                }
                                className="alerta-details"
                              >
                                Acessar detalhes da fazenda
                              </a>
                            </Collapse.Item>
                          </Collapse>
                        ))
                      ) : (
                        <div>Não há alertas no momento.</div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                }
              </Loading>
            </Tabs.Pane>
          </Tabs>
        </div>
      }
      <AlertaExpandido
        alerta={alertaExpandido}
        fecharModal={() => setAlertaExpandido(null)}
      />
      <TodosAlertas
        alerta={todosAlertas}
        fecharModal={() => setTodosAlertas(null)}
        aba={abaAtiva}
      />
    </div>
  );
};

export default Alertas;
